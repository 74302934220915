import React, { useState } from 'react';
import { ChevronDown, Loader2, RefreshCw, FileText } from 'lucide-react';
import { AIAnalysis, AnalysisType } from '../types/aiAnalysis';
import { analysisTypes } from '../constants/analysisTypes';

interface AIAnalysisDropdownProps {
  selectedType: AnalysisType | 'original';
  onSelect: (type: AnalysisType | 'original') => void;
  analyses: AIAnalysis[];
  isGenerating: boolean;
  onGenerate: (type: AnalysisType) => void;
}

export default function AIAnalysisDropdown({ 
  selectedType,
  onSelect,
  analyses,
  isGenerating,
  onGenerate 
}: AIAnalysisDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const currentAnalysis = selectedType === 'original' ? null : 
    analyses.find(a => a.type === selectedType);

  const handleSelect = (type: AnalysisType | 'original') => {
    onSelect(type);
    setIsOpen(false);
    if (type !== 'original' && !analyses.find(a => a.type === type)) {
      onGenerate(type as AnalysisType);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-2 rounded-lg bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 flex items-center justify-between group"
      >
        <div className="flex items-center gap-2">
          <FileText className="w-4 h-4 text-slate-400 group-hover:text-slate-600 dark:group-hover:text-slate-300" />
          <span className="text-slate-700 dark:text-slate-200">
            {selectedType === 'original' ? 'Original Entry' : (
              <>
                {analysisTypes[selectedType as AnalysisType].label}
                {isGenerating && selectedType !== 'original' && (
                  <span className="inline-flex items-center ml-2">
                    <Loader2 className="w-4 h-4 animate-spin mr-1" />
                    Generating...
                  </span>
                )}
              </>
            )}
          </span>
        </div>
        <ChevronDown className="w-4 h-4 text-slate-400" />
      </button>

      {selectedType !== 'original' && currentAnalysis && !isGenerating && (
        <button
          onClick={() => onGenerate(selectedType as AnalysisType)}
          className={`mt-2 px-3 py-1.5 text-sm rounded-lg flex items-center gap-2 transition-colors ${
            isGenerating
              ? 'bg-blue-100 dark:bg-blue-900/50 text-blue-700 dark:text-blue-200 cursor-wait'
              : 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-900/50'
          }`}
        >
          {isGenerating ? (
            <>
              <div className="relative w-4 h-4">
                <Loader2 className="absolute inset-0 w-4 h-4 animate-spin" />
                <div className="absolute inset-0 w-4 h-4 border-2 border-blue-200 dark:border-blue-800 rounded-full" />
              </div>
              <span>Regenerating...</span>
            </>
          ) : (
            <>
              <RefreshCw className="w-4 h-4" />
              <span>Regenerate {analysisTypes[selectedType as AnalysisType].label}</span>
            </>
          )}
        </button>
      )}

      {isOpen && (
        <div className="absolute top-full left-0 right-0 mt-1 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded-lg shadow-lg z-10">
          <div className="p-1">
            <button
              onClick={() => handleSelect('original')}
              className={`w-full px-3 py-2 text-left rounded ${
                selectedType === 'original'
                  ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                  : 'hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200'
              }`}
            >
              Original Entry
            </button>
            {Object.values(analysisTypes).map(type => {
              const existingAnalysis = analyses.find(a => a.type === type.id);
              return (
                <button
                  key={type.id}
                  onClick={() => handleSelect(type.id)}
                  className={`w-full px-3 py-2 text-left rounded flex items-center justify-between ${
                    selectedType === type.id
                      ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                      : 'hover:bg-slate-50 dark:hover:bg-slate-700 text-slate-700 dark:text-slate-200'
                  }`}
                >
                  <span>
                    {type.label}
                    {isGenerating && type.id === selectedType && (
                      <span className="inline-flex items-center ml-2 text-sm text-slate-500 dark:text-slate-400">
                        <div className="relative w-4 h-4">
                          <Loader2 className="absolute inset-0 w-4 h-4 animate-spin" />
                          <div className="absolute inset-0 w-4 h-4 border-2 border-blue-200 dark:border-blue-800 rounded-full" />
                        </div>
                        <span className="ml-1">Generating...</span>
                      </span>
                    )}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      )}
      {selectedType !== 'original' && (
        <div className="mt-2 text-sm text-slate-500 dark:text-slate-400">
          {analysisTypes[selectedType as AnalysisType].description}
        </div>
      )}
    </div>
  );
}