import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '../config/supabase';
import { useJournalStore } from '../store/useJournalStore';
import { useCollectionStore } from '../store/useCollectionStore';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signUp: (email: string, password: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  updateEmail: (newEmail: string) => Promise<void>;
  updatePassword: (newPassword: string) => Promise<void>;
  deleteAccount: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const authStateSubscription = useRef<{ unsubscribe: () => void } | null>(null);
  const resetJournalStore = useJournalStore(state => state.reset);
  const resetCollectionStore = useCollectionStore(state => state.reset);

  // Initialize auth state
  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      try {
        // Get initial session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;
        
        if (mounted) {
          setUser(session?.user ?? null);
        }

        // Set up auth state change subscription
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
          if (mounted) {
            if (event === 'SIGNED_IN') {
              setUser(session?.user ?? null);
            } else if (event === 'SIGNED_OUT') {
              setUser(null);
            } else if (event === 'USER_UPDATED') {
              setUser(session?.user ?? null);
            }
          }
        });

        authStateSubscription.current = subscription;

      } catch (error) {
        console.error('Auth initialization error:', error);
        if (mounted) {
          setUser(null);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initializeAuth();

    return () => {
      mounted = false;
      authStateSubscription.current?.unsubscribe();
    };
  }, []);

  const handleAuthError = useCallback((error: AuthError) => {
    console.error('Auth error:', error);
    
    if (error.message.includes('Email not confirmed')) {
      throw new Error('Please check your email for the confirmation link');
    }
    
    if (error.message.includes('Invalid login credentials')) {
      throw new Error('Invalid email or password');
    }
    
    if (error.message.includes('User already registered')) {
      throw new Error('Email is already registered');
    }
    
    throw error;
  }, []);

  const signUp = async (email: string, password: string) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
          data: {
            email_confirmed: true
          }
        }
      });
      
      if (error) throw error;

      // Sign in immediately after signup
      await signIn(email, password);
    } catch (error: any) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      setLoading(true);

      if (!email || !password) {
        throw new Error('Email and password are required');
      }

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });
      
      if (error) {
        if (error.message === 'Failed to fetch') {
          throw new Error('Unable to connect to authentication service. Please check your internet connection.');
        }
        throw error;
      }
      
      // Explicitly set user after successful sign in
      setUser(data.user);
      
    } catch (error: any) {
      console.error('Sign in error:', error);
      if (error.message === 'Failed to fetch') {
        throw new Error('Connection error. Please check your internet connection and try again.');
      }
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const logOut = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      
      // Clear store state
      resetJournalStore();
      resetCollectionStore();
      
      if (error) throw error;
      setUser(null);
    } catch (error: any) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateEmail = async (newEmail: string) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.updateUser({ email: newEmail });
      if (error) throw error;
    } catch (error: any) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const updatePassword = async (newPassword: string) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
    } catch (error: any) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    if (!user) throw new Error('No user logged in');
    
    try {
      setLoading(true);
      const { error: deleteDataError } = await supabase
        .from('journal_entries')
        .delete()
        .eq('user_id', user.id);
      
      if (deleteDataError) throw deleteDataError;

      await logOut();
    } catch (error: any) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    signUp,
    signIn,
    logOut,
    updateEmail,
    updatePassword,
    deleteAccount
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}