import React from 'react';
import { formatDate } from '../../utils/dateUtils';
import { JournalComment } from '../../types/journal';
import { CheckCircle, XCircle, Trash2 } from 'lucide-react';
import { compareDesc } from 'date-fns';

interface CommentListProps {
  comments: JournalComment[];
  onDelete: (commentId: string) => void;
}

export default function CommentList({ comments, onDelete }: CommentListProps) {
  if (comments.length === 0) {
    return (
      <p className="text-sm text-slate-500 dark:text-slate-400 italic">
        No comments yet
      </p>
    );
  }

  return (
    <div className="space-y-3">
      {[...comments]
        .sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt)))
        .map(comment => (
        <div
          key={comment.id}
          className={`p-3 rounded-lg border ${
            comment.status === 'on_track'
              ? 'bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-900'
              : 'bg-red-50 dark:bg-red-900/20 border-red-200 dark:border-red-900'
          }`}
        >
          <div className="flex items-start justify-between gap-3">
            <div className="flex items-center gap-2">
              {comment.status === 'on_track' ? (
                <CheckCircle className="w-4 h-4 text-green-500 dark:text-green-400 shrink-0" />
              ) : (
                <XCircle className="w-4 h-4 text-red-500 dark:text-red-400 shrink-0" />
              )}
              <p className={`text-sm ${
                comment.status === 'on_track'
                  ? 'text-green-700 dark:text-green-300'
                  : 'text-red-700 dark:text-red-300'
              }`}>
                {comment.content}
              </p>
            </div>
            <button
              onClick={() => onDelete(comment.id)}
              className={`p-1 rounded-full ${
                comment.status === 'on_track'
                  ? 'hover:bg-green-100 dark:hover:bg-green-900/40'
                  : 'hover:bg-red-100 dark:hover:bg-red-900/40'
              }`}
            >
              <Trash2 className="w-4 h-4 text-slate-400 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-300" />
            </button>
          </div>
          <p className="text-xs text-slate-500 dark:text-slate-400 mt-1">
            {formatDate(comment.createdAt)}
          </p>
        </div>
      ))}
    </div>
  );
}