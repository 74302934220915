import React, { useEffect } from 'react';
import { Settings as SettingsIcon, HelpCircle, Tag, Bot, Eye } from 'lucide-react';
import { useSettings } from '../contexts/SettingsContext';
import { useTagStore } from '../store/useTagStore';
import { defaultTags } from '../constants/defaultTags';
import { useAuth } from '../contexts/AuthContext';
import { analysisTypes } from '../constants/analysisTypes';

interface TooltipProps {
  content: string;
  example: string;
}

function Tooltip({ content, example }: TooltipProps) {
  return (
    <div className="group relative">
      <HelpCircle className="w-4 h-4 text-slate-400 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-300" />
      <div className="absolute left-6 top-1/2 -translate-y-1/2 w-64 p-3 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
        <p className="text-sm text-slate-600 dark:text-slate-300 mb-2">{content}</p>
        <p className="text-xs text-slate-500 dark:text-slate-400 italic">Example: {example}</p>
      </div>
    </div>
  );
}

export default function Settings({ onClose }: { onClose: () => void }) {
  const { aiPerspective, defaultView, setAiPerspective, setDefaultView } = useSettings();
  const { tags, toggleTag, fetchTags } = useTagStore();
  const { user } = useAuth();
  
  useEffect(() => {
    if (user) {
      fetchTags(user.id);
    }
  }, [user, fetchTags]);

  // Group system tags by category
  const tagsByCategory = defaultTags.reduce((acc, tag) => {
    const category = tag.category || 'Other';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(tag);
    return acc;
  }, {} as Record<string, typeof defaultTags>);

  const perspectives = {
    first: {
      label: "First Person",
      description: "Analysis from the writer's perspective",
      example: "I felt overwhelmed by the challenges I faced today..."
    },
    second: {
      label: "Second Person",
      description: "Analysis speaking directly to the writer",
      example: "You expressed feeling overwhelmed by the challenges you faced..."
    },
    third: {
      label: "Third Person",
      description: "Analysis from an observer's perspective",
      example: "The writer expressed feeling overwhelmed by the challenges they faced..."
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-slate-800 rounded-lg shadow-xl max-w-2xl w-full m-4 max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <SettingsIcon className="w-6 h-6 text-slate-700 dark:text-slate-200" />
            <h2 className="text-2xl font-semibold text-slate-900 dark:text-white">
              Settings
            </h2>
          </div>

          {/* AI Settings */}
          {/* AI Settings Section */}
          <div className="mb-8">
            <h3 className="text-lg font-medium text-slate-800 dark:text-slate-200 mb-4 flex items-center gap-2">
              <Bot className="w-5 h-5" />
              AI Settings
            </h3>
            <div className="space-y-6 bg-slate-50 dark:bg-slate-800/50 rounded-lg p-4">
              <div>
                <h4 className="text-sm font-medium text-slate-700 dark:text-slate-300 mb-3 flex items-center gap-2">
                  Analysis Perspective
                  <Tooltip 
                    content="Choose how AI analyses should be written" 
                    example="First person: I noticed... / Second person: You expressed... / Third person: The writer shared..."
                  />
                </h4>
                <div className="space-y-3">
                  {Object.entries(perspectives).map(([key, value]) => (
                    <div key={key} className="flex items-center gap-3">
                      <input
                        type="radio"
                        id={key}
                        name="perspective"
                        value={key}
                        checked={aiPerspective === key}
                        onChange={(e) => setAiPerspective(e.target.value as 'first' | 'second' | 'third')}
                        className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label
                        htmlFor={key}
                        className="text-sm text-slate-700 dark:text-slate-300"
                      >
                        {value.label}
                        <span className="block text-xs text-slate-500 dark:text-slate-400">
                          {value.example}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="border-t border-slate-200 dark:border-slate-700 pt-4 mt-4">
                <h4 className="text-sm font-medium text-slate-700 dark:text-slate-300 mb-3 flex items-center gap-2">
                  Default View
                  <Tooltip 
                    content="Choose which view to show by default when opening entries" 
                    example="Original Entry shows your text as written, Summary shows the AI summary"
                  />
                </h4>
                <div className="space-y-3">
                  <div className="flex items-center gap-3">
                    <input
                      type="radio"
                      id="original"
                      name="defaultView"
                      value="original"
                      checked={defaultView === 'original'}
                      onChange={(e) => setDefaultView(e.target.value as 'original')}
                      className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor="original"
                      className="text-sm text-slate-700 dark:text-slate-300"
                    >
                      Original Entry
                      <span className="block text-xs text-slate-500 dark:text-slate-400">
                        Show the entry as written
                      </span>
                    </label>
                  </div>
                  {Object.values(analysisTypes).map(type => (
                    <div key={type.id} className="flex items-center gap-3">
                      <input
                        type="radio"
                        id={type.id}
                        name="defaultView"
                        value={type.id}
                        checked={defaultView === type.id}
                        onChange={(e) => setDefaultView(e.target.value as typeof defaultView)}
                        className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label
                        htmlFor={type.id}
                        className="text-sm text-slate-700 dark:text-slate-300"
                      >
                        {type.label}
                        <span className="block text-xs text-slate-500 dark:text-slate-400">
                          {type.description}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Tag Settings */}
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-slate-800 dark:text-slate-200 mb-4 flex items-center gap-2">
                <Tag className="w-5 h-5" />
                Tag Settings
                <Tooltip 
                  content="Choose which system tags appear in the tag suggestions when writing entries" 
                  example="Enable tags like 'Achievements' to quickly categorize your entries"
                />
              </h3>
              <div className="space-y-6 bg-slate-50 dark:bg-slate-800/50 rounded-lg p-4">
                {Object.entries(tagsByCategory).map(([category, categoryTags]) => (
                  <div key={category} className="space-y-3">
                    <h4 className="text-sm font-medium text-slate-600 dark:text-slate-300">
                      {category}
                    </h4>
                    <div className="space-y-3">
                      {categoryTags.map(defaultTag => {
                        const userTag = tags.find(t => t.isSystem && t.tag === defaultTag.tag);
                        return (
                          <div key={defaultTag.tag} className="flex items-start gap-3">
                            <div className="pt-0.5">
                              <input
                                type="checkbox"
                                id={defaultTag.tag}
                                checked={userTag?.enabled ?? false}
                                onChange={(e) => {
                                  if (userTag) {
                                    toggleTag(userTag.id, e.target.checked);
                                  }
                                }}
                                className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 rounded"
                              />
                            </div>
                            <label htmlFor={defaultTag.tag} className="flex-1 cursor-pointer">
                              <span className="block text-sm font-medium text-slate-700 dark:text-slate-200">
                                {defaultTag.tag}
                              </span>
                              <span className="block text-xs text-slate-500 dark:text-slate-400 mt-0.5">
                                {defaultTag.description}
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-slate-800 dark:text-slate-200 mb-4 flex items-center gap-2">
                <Eye className="w-5 h-5" />
                Default Entry View
              </h3>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <input
                    type="radio"
                    id="original"
                    name="defaultView"
                    value="original"
                    checked={defaultView === 'original'}
                    onChange={(e) => setDefaultView(e.target.value as 'original')}
                    className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                  />
                  <label
                    htmlFor="original"
                    className="flex-1 text-slate-700 dark:text-slate-300"
                  >
                    Original Entry
                  </label>
                </div>
                {Object.values(analysisTypes).map(type => (
                  <div key={type.id} className="flex items-center gap-4">
                    <input
                      type="radio"
                      id={type.id}
                      name="defaultView"
                      value={type.id}
                      checked={defaultView === type.id}
                      onChange={(e) => setDefaultView(e.target.value as typeof defaultView)}
                      className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor={type.id}
                      className="flex-1 text-slate-700 dark:text-slate-300"
                    >
                      {type.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-slate-800 dark:text-slate-200 mb-4">
                AI Analysis Perspective
              </h3>
              <div className="space-y-4">
                {Object.entries(perspectives).map(([key, value]) => (
                  <div key={key} className="flex items-center gap-4">
                    <input
                      type="radio"
                      id={key}
                      name="perspective"
                      value={key}
                      checked={aiPerspective === key}
                      onChange={(e) => setAiPerspective(e.target.value as 'first' | 'second' | 'third')}
                      className="w-4 h-4 text-blue-500 border-slate-300 dark:border-slate-600 focus:ring-blue-500 dark:focus:ring-blue-600"
                    />
                    <label
                      htmlFor={key}
                      className="flex-1 flex items-center gap-2 text-slate-700 dark:text-slate-300"
                    >
                      <span>{value.label}</span>
                      <Tooltip content={value.description} example={value.example} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 p-6 border-t border-slate-200 dark:border-slate-700 bg-white dark:bg-slate-800">
          <button
            onClick={onClose}
            className="w-full py-2 px-4 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
          >
            Save & Close
          </button>
        </div>
      </div>
    </div>
  );
}