import React, { useState, useEffect } from 'react';
import { Trash2, Tag, ChevronDown, ChevronUp, Loader2, AlertCircle, ChevronLeft, PenLine, Menu, RefreshCw, FileText } from 'lucide-react';
import { useJournalStore } from '../store/useJournalStore';
import { useAuth } from '../contexts/AuthContext';
import { useCollectionStore } from '../store/useCollectionStore';
import { formatDate } from '../utils/dateUtils';
import { analysisTypes } from '../constants/analysisTypes';
import DeleteConfirmModal from './DeleteConfirmModal';
import CoverImageStrip from './CoverImageStrip';
import { AnalysisType } from '../types/aiAnalysis';

interface JournalListProps {
  onEditEntry: (id: string) => void;
  onBackToCollections: () => void;
  onNewEntry: () => void;
  onOpenMenu: () => void;
}

export default function JournalList({ onEditEntry, onBackToCollections, onNewEntry, onOpenMenu }: JournalListProps) {
  const { entries, loading, fetchEntries, deleteEntry, generateAnalysis, updateEntry } = useJournalStore();
  const { user } = useAuth();
  const { selectedCollectionId } = useCollectionStore();
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [expandedEntry, setExpandedEntry] = useState<string | null>(null);
  const [generatingAnalysis, setGeneratingAnalysis] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (user && selectedCollectionId) {
      fetchEntries(user.id, selectedCollectionId);
    }
  }, [user, selectedCollectionId, fetchEntries]);

  const handleDeleteClick = (id: string) => {
    const entry = entries.find(e => e.id === id);
    if (entry) {
      setEntryToDelete(id);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteConfirm = async () => {
    if (!entryToDelete) return;

    setDeletingId(entryToDelete);
    setDeleteError(null);
    setShowDeleteModal(false);

    try {
      await deleteEntry(entryToDelete);
    } catch (error) {
      console.error('Delete error:', error);
      setDeleteError('Failed to delete entry. Please try again.');
      const timer = setTimeout(() => setDeleteError(null), 3000);
      return () => clearTimeout(timer);
    } finally {
      setDeletingId(null);
      setEntryToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setEntryToDelete(null);
  };

  const handleRegenerateAnalysis = async (entryId: string, type: AnalysisType) => {
    const entry = entries.find(e => e.id === entryId);
    if (!entry) return;

    setGeneratingAnalysis(entryId);
    try {
      const analysis = await generateAnalysis(entry.content, type);
      const timestamp = new Date().toISOString();
      const newAnalyses = [
        ...entry.analyses.filter(a => a.type !== type),
        { ...analysis, createdAt: timestamp }
      ];
      
      await updateEntry(entryId, entry.title, entry.content, entry.tags, newAnalyses);
    } catch (error) {
      console.error('Analysis regeneration error:', error);
    } finally {
      setGeneratingAnalysis(null);
    }
  };

  const allTags = Array.from(
    new Set(entries.flatMap(entry => entry.tags))
  ).sort();

  const filteredEntries = entries
    .filter(entry => !selectedTag || entry.tags.includes(selectedTag));

  if (loading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="w-6 h-6 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (!selectedCollectionId) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="text-center">
          <p className="text-slate-500 dark:text-slate-400">
            Please select a journal to view entries
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <CoverImageStrip collectionId={selectedCollectionId} />

      {deleteError && (
        <div className="flex items-center gap-2 p-3 rounded-lg bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-300">
          <AlertCircle className="w-4 h-4 shrink-0" />
          <span>{deleteError}</span>
        </div>
      )}

      <div className="sticky top-0 z-10 bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800 py-4">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <button
              onClick={onOpenMenu}
              className="md:hidden p-2 rounded-lg hover:bg-slate-100 dark:hover:bg-slate-800"
            >
              <Menu className="w-6 h-6 text-slate-600 dark:text-slate-300" />
            </button>
            <button
              onClick={onBackToCollections}
              className="flex items-center gap-2 text-slate-600 dark:text-slate-300 hover:text-slate-900 dark:hover:text-white transition-colors duration-200"
            >
              <ChevronLeft className="w-5 h-5" />
              <span>Back to Journals</span>
            </button>
          </div>
          {entries.length > 0 && (
            <button
              onClick={onNewEntry}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
            >
              <PenLine className="w-4 h-4" />
              New Entry
            </button>
          )}
        </div>
      </div>

      {allTags.length > 0 && (
        <div className="flex gap-2 items-center">
          <Tag className="w-4 h-4 text-slate-500" />
          <div className="flex gap-2 flex-wrap">
            <button
              onClick={() => setSelectedTag(null)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedTag === null
                  ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300'
                  : 'bg-slate-100 dark:bg-slate-800 text-slate-600 dark:text-slate-300 hover:bg-slate-200 dark:hover:bg-slate-700'
              }`}
            >
              All
            </button>
            {allTags.map(tag => (
              <button
                key={tag}
                onClick={() => setSelectedTag(tag === selectedTag ? null : tag)}
                className={`px-3 py-1 rounded-full text-sm ${
                  tag === selectedTag
                    ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300'
                    : 'bg-slate-100 dark:bg-slate-800 text-slate-600 dark:text-slate-300 hover:bg-slate-200 dark:hover:bg-slate-700'
                }`}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>
      )}

      {filteredEntries.length === 0 ? (
        <div className="text-center py-12">
          <div className="flex flex-col items-center gap-4">
            <p className="text-slate-500 dark:text-slate-400">
              No journal entries yet.
            </p>
            <button
              onClick={onNewEntry}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200"
            >
              <PenLine className="w-4 h-4" />
              Start Writing
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredEntries.map((entry) => (
            <div
              key={entry.id}
              className="p-4 rounded-lg bg-white/50 dark:bg-slate-800/50 border border-slate-200 dark:border-slate-700"
            >
              <div className="flex justify-between items-start mb-2">
                <div className="flex-1 cursor-pointer" onClick={() => onEditEntry(entry.id)}>
                  <h3 className="text-lg font-medium text-slate-700 dark:text-slate-200 mb-1 hover:text-blue-500 dark:hover:text-blue-400">
                    {entry.title}
                  </h3>
                  <p className="text-sm text-slate-500 dark:text-slate-400">
                    {formatDate(entry.createdAt)}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setExpandedEntry(
                      expandedEntry === entry.id ? null : entry.id
                    )}
                    className="p-1 rounded hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-400 hover:text-slate-600 dark:hover:text-slate-200 transition-colors"
                  >
                    {expandedEntry === entry.id ? (
                      <ChevronUp className="w-4 h-4" />
                    ) : (
                      <ChevronDown className="w-4 h-4" />
                    )}
                  </button>
                  <button
                    onClick={() => handleDeleteClick(entry.id)}
                    disabled={deletingId === entry.id}
                    className="p-1 rounded hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-400 hover:text-red-500 transition-colors disabled:opacity-50"
                  >
                    <Trash2 className={`w-4 h-4 ${deletingId === entry.id ? 'animate-pulse' : ''}`} />
                  </button>
                </div>
              </div>

              {entry.tags.length > 0 && (
                <div className="flex gap-2 mt-2 flex-wrap">
                  {entry.tags.map((tag, index) => (
                    <span
                      key={`${entry.id}-tag-${index}`}
                      className="px-2 py-0.5 rounded-full bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 text-xs"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
              {expandedEntry === entry.id && (
                <div className="mt-4 space-y-4">
                  {entry.analyses.find(a => a.type === 'summary') ? (
                    <div className="rounded-lg bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-950/50 dark:to-indigo-950/50 p-4">
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-2">
                          <FileText className="w-4 h-4 text-blue-600 dark:text-blue-400" />
                          <h4 className="text-sm font-medium text-blue-600 dark:text-blue-400">
                            Summary
                          </h4>
                        </div>
                        <button
                          onClick={() => handleRegenerateAnalysis(entry.id, 'summary')}
                          disabled={generatingAnalysis === entry.id}
                          className="text-xs px-2 py-1 rounded bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-900/50 flex items-center gap-1"
                        >
                          {generatingAnalysis === entry.id ? (
                            <>
                              <Loader2 className="w-3 h-3 animate-spin" />
                              Regenerating...
                            </>
                          ) : (
                            <>
                              <RefreshCw className="w-3 h-3" />
                              Regenerate
                            </>
                          )}
                        </button>
                      </div>
                      <p className="text-slate-600 dark:text-slate-300">
                        {entry.analyses.find(a => a.type === 'summary')?.content}
                      </p>
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <button
                        onClick={() => handleRegenerateAnalysis(entry.id, 'summary')}
                        disabled={generatingAnalysis === entry.id}
                        className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 mx-auto"
                      >
                        {generatingAnalysis === entry.id ? (
                          <>
                            <Loader2 className="w-4 h-4 animate-spin" />
                            Generating Summary...
                          </>
                        ) : (
                          <>
                            <FileText className="w-4 h-4" />
                            Generate Summary
                          </>
                        )}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {showDeleteModal && entryToDelete && (
        <DeleteConfirmModal
          entryTitle={entries.find(e => e.id === entryToDelete)?.title || ''}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
    </div>
  );
}
