import { FileText, Wand2, Brain } from 'lucide-react';
import { AIAnalysisType } from '../types/aiAnalysis';

export const analysisTypes: Record<string, AIAnalysisType> = {
  summary: {
    id: 'summary',
    label: 'Summary',
    description: 'A concise overview of your entry for easy reference',
    icon: FileText,
    prompt: 'Provide a clear and concise summary of this journal entry, highlighting the main points and key thoughts.'
  },
  format: {
    id: 'format',
    label: 'Edit for Grammar',
    description: 'Fix grammar and improve readability',
    icon: Wand2,
    prompt: 'Fix any grammar, spelling, and punctuation errors in this text. Improve readability by adjusting sentence structure where needed, but maintain the original meaning and tone. Return the complete text with all corrections applied. Do not change the perspective or voice of the writing.'
  },
  analysis: {
    id: 'analysis',
    label: 'Analysis',
    description: 'Discover meaningful patterns and reflections',
    icon: Brain,
    prompt: 'Analyze this journal entry thoughtfully. Identify key themes, emotional patterns, and meaningful insights while maintaining a supportive and understanding tone.'
  }
} as const;