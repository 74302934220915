import { create } from 'zustand';
import { supabase } from '../config/supabase';
import type { DefaultTag } from '../constants/defaultTags';
import { defaultTags } from '../constants/defaultTags';

interface UserTag {
  id: string;
  tag: string;
  isSystem: boolean;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

interface TagState {
  tags: UserTag[];
  loading: boolean;
  error: string | null;
  fetchTags: (userId: string) => Promise<void>;
  addTag: (userId: string, tag: string) => Promise<void>;
  toggleTag: (tagId: string, enabled: boolean) => Promise<void>;
  initializeSystemTags: (userId: string) => Promise<void>;
}

export const useTagStore = create<TagState>((set, get) => ({
  tags: [],
  loading: false,
  error: null,

  fetchTags: async (userId: string) => {
    if (!userId) return;
    
    set({ loading: true, error: null });

    try {
      const { data: tags, error } = await supabase
        .from('user_tags')
        .select('*')
        .eq('user_id', userId)
        .order('tag');

      if (error) throw error;

      set({
        tags: tags.map(tag => ({
          id: tag.id,
          tag: tag.tag,
          isSystem: tag.is_system,
          enabled: tag.enabled,
          createdAt: tag.created_at,
          updatedAt: tag.updated_at
        })),
        loading: false
      });
    } catch (error: any) {
      console.error('Error fetching tags:', error);
      set({ error: error.message, loading: false });
    }
  },

  addTag: async (userId: string, tag: string) => {
    try {
      const { data, error } = await supabase
        .from('user_tags')
        .insert({
          user_id: userId,
          tag,
          is_system: false,
          enabled: true
        })
        .select()
        .single();

      if (error) throw error;

      set(state => ({
        tags: [...state.tags, {
          id: data.id,
          tag: data.tag,
          isSystem: data.is_system,
          enabled: data.enabled,
          createdAt: data.created_at,
          updatedAt: data.updated_at
        }]
      }));
    } catch (error: any) {
      console.error('Error adding tag:', error);
      throw error;
    }
  },

  toggleTag: async (tagId: string, enabled: boolean) => {
    try {
      const { error } = await supabase
        .from('user_tags')
        .update({ enabled })
        .eq('id', tagId);

      if (error) throw error;

      set(state => ({
        tags: state.tags.map(tag =>
          tag.id === tagId ? { ...tag, enabled } : tag
        )
      }));
    } catch (error: any) {
      console.error('Error toggling tag:', error);
      throw error;
    }
  },

  initializeSystemTags: async (userId: string) => {
    try {
      const { data: existingTags, error: fetchError } = await supabase
        .from('user_tags')
        .select('tag')
        .eq('user_id', userId)
        .eq('is_system', true);

      if (fetchError) throw fetchError;

      const existingSystemTags = new Set(existingTags?.map(t => t.tag) || []);
      const tagsToAdd = defaultTags.filter(tag => !existingSystemTags.has(tag.tag));

      if (tagsToAdd.length === 0) return;

      const { error: insertError } = await supabase
        .from('user_tags')
        .insert(
          tagsToAdd.map(tag => ({
            user_id: userId,
            tag: tag.tag,
            is_system: true,
            enabled: true
          }))
        );

      if (insertError) throw insertError;

      // Refresh tags after initialization
      await get().fetchTags(userId);
    } catch (error: any) {
      console.error('Error initializing system tags:', error);
      throw error;
    }
  }
}));