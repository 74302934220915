interface DefaultTag {
  tag: string;
  description: string;
  category?: string;
}

export const defaultTags = [
  {
    tag: 'Achievements',
    description: 'Record your accomplishments and milestones',
    category: 'Progress'
  },
  {
    tag: 'Challenges',
    description: 'Document obstacles and how you overcome them',
    category: 'Progress'
  },
  {
    tag: 'Goals',
    description: 'Track your aspirations and objectives',
    category: 'Progress'
  },
  {
    tag: 'Learning',
    description: 'Capture new knowledge and insights',
    category: 'Growth'
  },
  {
    tag: 'Personal Growth',
    description: 'Document your development journey',
    category: 'Growth'
  },
  {
    tag: 'Reflection',
    description: 'Thoughtful contemplation and insights',
    category: 'Growth'
  },
  {
    tag: 'Gratitude',
    description: 'Express appreciation and thankfulness',
    category: 'Mindset'
  },
  {
    tag: 'Mindfulness',
    description: 'Present moment awareness and observations',
    category: 'Mindset'
  },
  {
    tag: 'Ideas',
    description: 'Capture creative thoughts and inspirations',
    category: 'Creativity'
  },
  {
    tag: 'Wellness',
    description: 'Track health and well-being activities',
    category: 'Health'
  }
] as const satisfies DefaultTag[];