import CryptoJS from 'crypto-js';
import { anonymizeContent, AnonymizationResult, deanonymizeContent } from './anonymization';

const ENCRYPTION_VERSION = '1';

export function encryptContent(content: string, userId: string, anonymize = false): string {
  if (!content || !userId) return content;
  try {
    let dataToEncrypt: any = { content };

    if (anonymize) {
      const { text, replacements }: AnonymizationResult = anonymizeContent(content);
      dataToEncrypt = {
        content: text,
        replacements,
        isAnonymized: true
      };
    }
    
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(dataToEncrypt),
      userId
    );

    return `${ENCRYPTION_VERSION}:${encrypted.toString()}`;

  } catch (error) {
    console.error('Encryption error:', error);
    return content;
  }
}

export function decryptContent(content: string, userId: string): string {
  if (!content || !userId) return content;
  try {
    if (!content.startsWith(`${ENCRYPTION_VERSION}:`)) return content;
    
    const encryptedData = content.substring(content.indexOf(':') + 1);
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, userId);
    const decryptedString = decryptedBytes
      .toString(CryptoJS.enc.Utf8);

    try {
      const data = JSON.parse(decryptedString);
      
      if (data.isAnonymized && data.replacements) {
        return deanonymizeContent(data.content, data.replacements);
      }
      
      return data.content || content;

    } catch (error) {
      // For backward compatibility with old format
      console.warn('Failed to parse decrypted content as JSON, using raw content');
      return decryptedString || content;
    }

  } catch (error) {
    console.error('Decryption error:', error);
    return content;
  }
}

export function isEncrypted(content: string): boolean {
  return content?.startsWith(`${ENCRYPTION_VERSION}:`);
}