interface Replacement {
  placeholder: string;
  original: string;
  type: string;
}

export interface AnonymizationResult {
  text: string;
  replacements: Replacement[];
}

// Regular expressions for matching PII
const patterns = {
  email: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g,
  phone: /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g,
  names: /\b(Mr\.|Mrs\.|Ms\.|Dr\.)\s+[A-Z][a-z]+(\s+[A-Z][a-z]+)*\b|\b[A-Z][a-z]+(\s+[A-Z][a-z]+){1,2}\b/g,
  address: /\b\d+\s+[A-Za-z\s,]+(?:Street|St|Avenue|Ave|Road|Rd|Boulevard|Blvd|Lane|Ln|Drive|Dr|Court|Ct|Circle|Cir|Trail|Trl|Way|Place|Pl)\b/gi,
  postalCode: /\b[A-Z]\d[A-Z]\s?\d[A-Z]\d\b|\b\d{5}(-\d{4})?\b/g,
  url: /https?:\/\/[^\s]+/g,
  ip: /\b(?:\d{1,3}\.){3}\d{1,3}\b/g
};

export function anonymizeContent(content: string): AnonymizationResult {
  let anonymized = content;
  const replacements: Replacement[] = [];
  let counter = 1;

  Object.entries(patterns).forEach(([type, pattern]) => {
    anonymized = anonymized.replace(pattern, (match) => {
      const placeholder = `[${type.toUpperCase()}_${counter++}]`;
      replacements.push({
        placeholder,
        original: match,
        type
      });
      return placeholder;
    });
  });

  return { text: anonymized, replacements };
}

export function deanonymizeContent(anonymized: string, replacements: Replacement[]): string {
  let deanonymized = anonymized;
  replacements.forEach(({ placeholder, original }) => {
    deanonymized = deanonymized.replace(placeholder, original);
  });
  return deanonymized;
}