import React, { useState } from 'react';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';

interface AddCommentProps {
  onSubmit: (content: string, status: 'on_track' | 'off_track') => Promise<void>;
}

export default function AddComment({ onSubmit }: AddCommentProps) {
  const [content, setContent] = useState('');
  const [status, setStatus] = useState<'on_track' | 'off_track'>('on_track');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim() || isSubmitting) return;

    setIsSubmitting(true);
    try {
      await onSubmit(content.trim(), status);
      setContent('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="flex gap-2">
        <button
          type="button"
          onClick={() => setStatus('on_track')}
          className={`flex-1 py-2 px-3 rounded-lg border flex items-center justify-center gap-2 ${
            status === 'on_track'
              ? 'bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-900 text-green-700 dark:text-green-300'
              : 'bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300'
          }`}
        >
          <CheckCircle className="w-4 h-4" />
          <span>On Track</span>
        </button>
        <button
          type="button"
          onClick={() => setStatus('off_track')}
          className={`flex-1 py-2 px-3 rounded-lg border flex items-center justify-center gap-2 ${
            status === 'off_track'
              ? 'bg-red-50 dark:bg-red-900/20 border-red-200 dark:border-red-900 text-red-700 dark:text-red-300'
              : 'bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 text-slate-600 dark:text-slate-300'
          }`}
        >
          <XCircle className="w-4 h-4" />
          <span>Off Track</span>
        </button>
      </div>

      <div className="flex gap-2">
        <input
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Add a comment..."
          className="flex-1 px-3 py-2 rounded-lg border border-slate-200 dark:border-slate-700 bg-white dark:bg-slate-800 text-slate-900 dark:text-white placeholder-slate-400 dark:placeholder-slate-500"
        />
        <button
          type="submit"
          disabled={!content.trim() || isSubmitting}
          className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-4 h-4 animate-spin" />
              <span>Adding...</span>
            </>
          ) : (
            <span>Add</span>
          )}
        </button>
      </div>
    </form>
  );
}