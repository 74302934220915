import { create } from 'zustand';
import OpenAI from 'openai';
import { supabase } from '../config/supabase';
import { encryptContent, decryptContent } from '../utils/encryption';
import { JournalEntry } from '../types/journal';
import { AIAnalysis, AnalysisType } from '../types/aiAnalysis';
import { analysisTypes } from '../constants/analysisTypes';
import { anonymizeContent } from '../utils/anonymization';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true
});

interface JournalState {
  entries: JournalEntry[];
  loading: boolean;
  error: string | null;
  addComment: (entryId: string, content: string, status: 'on_track' | 'off_track') => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
  fetchEntries: (userId: string, collectionId: string) => Promise<void>;
  addEntry: (title: string, content: string, tags: string[], analyses: AIAnalysis[], userId: string, collectionId: string) => Promise<string>;
  updateEntry: (id: string, title: string, content: string, tags: string[], analyses: AIAnalysis[]) => Promise<void>;
  deleteEntry: (id: string) => Promise<void>;
  getEntry: (id: string) => JournalEntry | undefined;
  generateAnalysis: (content: string, type: AnalysisType, perspective: 'first' | 'second' | 'third') => Promise<Omit<AIAnalysis, 'createdAt'>>;
  reset: () => void;
}

export const useJournalStore = create<JournalState>((set, get) => ({
  entries: [],
  loading: false,
  error: null,

  addComment: async (entryId: string, content: string, status: 'on_track' | 'off_track') => {
    try {
      const userId = (await supabase.auth.getUser()).data.user?.id;
      if (!userId) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('journal_comments')
        .insert({
          entry_id: entryId,
          user_id: userId,
          content,
          status
        })
        .select()
        .single();

      if (error) throw error;

      set(state => ({
        entries: state.entries.map(entry =>
          entry.id === entryId
            ? {
                ...entry,
                comments: [
                  ...entry.comments,
                  {
                    id: data.id,
                    entryId: data.entry_id,
                    userId: data.user_id,
                    content: data.content,
                    status: data.status,
                    createdAt: data.created_at,
                    updatedAt: data.updated_at
                  }
                ]
              }
            : entry
        )
      }));
    } catch (error: any) {
      console.error('Error adding comment:', error);
      throw error;
    }
  },

  deleteComment: async (commentId: string) => {
    try {
      const { error } = await supabase
        .from('journal_comments')
        .delete()
        .eq('id', commentId);

      if (error) throw error;

      set(state => ({
        entries: state.entries.map(entry => ({
          ...entry,
          comments: entry.comments.filter(comment => comment.id !== commentId)
        }))
      }));
    } catch (error: any) {
      console.error('Error deleting comment:', error);
      throw error;
    }
  },

  fetchEntries: async (userId: string, collectionId: string) => {
    if (!userId || !collectionId) return;
    
    set({ loading: true, error: null });

    try {
      const { data: entries, error } = await supabase
        .from('journal_entries')
        .select(`
          *,
          journal_comments (
            id,
            entry_id,
            user_id,
            content,
            status,
            created_at,
            updated_at
          )
        `)
        .eq('user_id', userId)
        .eq('collection_id', collectionId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (!entries) {
        throw new Error('No entries returned from database');
      }

      const decryptedEntries = entries.map(entry => ({
        id: entry.id,
        title: entry.title,
        content: decryptContent(entry.content, userId),
        createdAt: entry.created_at,
        updatedAt: entry.updated_at,
        tags: entry.tags || [],
        comments: (entry.journal_comments || []).map(comment => ({
          id: comment.id,
          entryId: comment.entry_id,
          userId: comment.user_id,
          content: comment.content,
          status: comment.status,
          createdAt: comment.created_at,
          updatedAt: comment.updated_at
        })),
        analyses: (entry.analyses || []).map((analysis: AIAnalysis) => ({
          ...analysis,
          content: decryptContent(analysis.content, userId)
        })),
        userId: entry.user_id,
        collectionId: entry.collection_id
      }));

      set({ entries: decryptedEntries, loading: false, error: null });
    } catch (error: any) {
      console.error('Fetch error:', error);
      set({ loading: false, error: 'Failed to fetch entries' });
    }
  },

  addEntry: async (title: string, content: string, tags: string[], analyses: AIAnalysis[], userId: string, collectionId: string) => {
    try {
      const timestamp = new Date().toISOString();
      
      // Check for existing entry with same title in this collection
      const { data: existingEntries } = await supabase
        .from('journal_entries')
        .select('id')
        .eq('user_id', userId)
        .eq('collection_id', collectionId)
        .eq('title', title)
        .limit(1);

      if (existingEntries && existingEntries.length > 0) {
        // Update existing entry instead of creating new one
        const existingId = existingEntries[0].id;
        await get().updateEntry(existingId, title, content, tags, analyses);
        return existingId;
      }
      
      const encryptedContent = encryptContent(content, userId);
      const encryptedAnalyses = analyses.map(analysis => ({
        ...analysis,
        content: encryptContent(analysis.content, userId, true) // Anonymize analysis content
      }));

      const { data, error } = await supabase
        .from('journal_entries')
        .insert({
          user_id: userId,
          collection_id: collectionId,
          title,
          content: encryptedContent,
          tags,
          analyses: encryptedAnalyses,
          created_at: timestamp,
          updated_at: timestamp
        })
        .select()
        .single();

      if (error) throw error;

      if (!data) {
        throw new Error('No data returned from entry creation');
      }

      const decryptedEntry: JournalEntry = {
        id: data.id,
        title: data.title,
        content: content,
        createdAt: timestamp,
        updatedAt: timestamp,
        tags: data.tags || [],
        analyses: analyses,
        userId: data.user_id,
        collectionId: data.collection_id
      };

      set(state => ({
        entries: [decryptedEntry, ...state.entries]
      }));

      return data.id;
    } catch (error: any) {
      console.error('Error adding entry:', error);
      throw error;
    }
  },

  updateEntry: async (id: string, title: string, content: string, tags: string[], analyses: AIAnalysis[]) => {
    try {
      const userId = (await supabase.auth.getUser()).data.user?.id;
      if (!userId) throw new Error('User not authenticated');

      const timestamp = new Date().toISOString();

      const encryptedContent = encryptContent(content, userId);
      const encryptedAnalyses = analyses.map(analysis => ({
        ...analysis,
        content: encryptContent(analysis.content, userId, true) // Anonymize analysis content
      }));

      const { data, error } = await supabase
        .from('journal_entries')
        .update({
          title,
          content: encryptedContent,
          tags,
          analyses: encryptedAnalyses,
          updated_at: timestamp
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      if (!data) {
        throw new Error('No data returned from entry update');
      }

      set(state => ({
        entries: state.entries.map(entry =>
          entry.id === id
            ? {
                ...entry,
                title,
                content,
                tags,
                analyses,
                updatedAt: timestamp
              }
            : entry
        )
      }));
    } catch (error: any) {
      console.error('Error updating entry:', error);
      throw error;
    }
  },

  deleteEntry: async (id: string) => {
    try {
      const { error } = await supabase
        .from('journal_entries')
        .delete()
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        entries: state.entries.filter(entry => entry.id !== id)
      }));
    } catch (error: any) {
      console.error('Error deleting entry:', error);
      throw error;
    }
  },

  getEntry: (id: string) => {
    return get().entries.find(entry => entry.id === id);
  },

  generateAnalysis: async (content: string, type: AnalysisType, perspective: 'first' | 'second' | 'third') => {
    try {
      const analysisType = analysisTypes[type];
      const maxTokens = type === 'format' ? 2000 : type === 'analysis' ? 800 : 400;

      // Only apply perspective to summary and analysis types
      const perspectivePrompt = type !== 'format' ? (() => {
        switch (perspective) {
          case 'first':
            return 'Write from a first-person perspective, using "I" statements.';
          case 'second':
            return 'Write directly to the author using "you" statements.';
          case 'third':
            return 'Write from an observer\'s perspective using "the writer" or "they" statements.';
        }
      })() : '';
      
      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: type === 'format' ? analysisType.prompt : `${perspectivePrompt} ${analysisType.prompt}`
          },
          {
            role: "user",
            content: content // Content is already anonymized during encryption
          }
        ],
        temperature: 0.7,
        max_tokens: maxTokens
      });

      return {
        type,
        content: response.choices[0]?.message?.content || `Failed to generate ${analysisType.label.toLowerCase()}`
      };
    } catch (error: any) {
      console.error('AI analysis generation failed:', error);
      throw new Error(error.message || 'Failed to generate analysis');
    }
  },

  reset: () => {
    set({ entries: [], loading: false, error: null });
  }
}));