import React from 'react';
import { Feather, ExternalLink } from 'lucide-react';
import pkg from '../../package.json';

const features = [
  {
    title: 'AI-Powered Insights',
    description: 'Smart insights that understand your writing while keeping your personal details private'
  },
  {
    title: 'End-to-End Encryption',
    description: 'Your entries are protected with military-grade AES-256-GCM encryption'
  },
  {
    title: 'Offline Support',
    description: 'Continue journaling even without an internet connection'
  },
  {
    title: 'Smart Tagging',
    description: 'Organize your entries with an intuitive tagging system'
  },
  {
    title: 'Dark Mode',
    description: 'Easy on the eyes with automatic dark mode support'
  },
  {
    title: 'Cross-Platform',
    description: 'Access your journal from any device with a web browser'
  }
];

export default function LandingPage({ onGetStarted }: { onGetStarted: () => void }) {
  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="relative inline-block mb-8">
            <div className="absolute inset-0 bg-blue-100 dark:bg-blue-900/30 rounded-full transform scale-150 blur-2xl"></div>
            <Feather className="relative w-24 h-24 text-blue-500 dark:text-blue-400" />
          </div>

          <h1 className="text-5xl md:text-6xl font-light text-slate-900 dark:text-white mb-6 font-['Inter']">
            Living Journal
          </h1>
          <p className="text-xl text-slate-600 dark:text-slate-300 mb-12 max-w-3xl mx-auto">
            Your mindful space for daily reflection, powered by AI insights and protected by advanced encryption
          </p>

          <button
            onClick={onGetStarted}
            className="px-8 py-4 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 text-lg font-medium shadow-lg hover:shadow-xl"
          >
            Get Started
          </button>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="p-6 rounded-xl bg-white/50 dark:bg-slate-800/50 backdrop-blur-sm border border-slate-200 dark:border-slate-700"
            >
              <h3 className="text-lg font-medium text-slate-900 dark:text-white mb-2">
                {feature.title}
              </h3>
              <p className="text-slate-600 dark:text-slate-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-light text-slate-900 dark:text-white mb-4">
            Ready to start your journaling journey?
          </h2>
          <button
            onClick={onGetStarted}
            className="px-8 py-4 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 text-lg font-medium shadow-lg hover:shadow-xl"
          >
            Begin Writing Now
          </button>
        </div>

        {/* Footer */}
        <footer className="text-center text-slate-600 dark:text-slate-400">
          <div className="flex flex-col items-center gap-2">
            <p className="text-sm">v{pkg.version}</p>
            <div className="flex items-center gap-2 text-sm">
              <a
                href="https://wildapps.net/development/ai/how-living-journal-protects-user-privacy-and-keeps-your-data-safe/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Privacy Policy
              </a>
              <span>•</span>
              <a
                href="https://wildapps.net/category/projects/living-journal/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Blog
              </a>
              <span>•</span>
              <a
                href="https://wildapps.net/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Contact
              </a>
              <span>•</span>
              <span>Forged by</span>
              <a
                href="https://wildapps.net"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1 text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300"
              >
                Wild Apps
                <ExternalLink className="w-3 h-3" />
              </a>
            </div>
            <p className="text-sm">© 2024 All rights reserved</p>
          </div>
        </footer>
      </div>
    </div>
  );
}