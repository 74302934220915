import { ChangelogEntry } from '../types/changelog';

export const changelog: ChangelogEntry[] = [
  {
    version: '1.35.0',
    date: '2025-03-26',
    changes: [
      'Added comments feature to journal entries',
      'Added on-track/off-track status for entry comments',
      'Improved AI analysis display and controls',
      'Enhanced tag management with better organization',
      'Fixed AI analysis truncation issues'
    ]
  },
  {
    version: '1.34.0',
    date: '2025-03-26',
    changes: [
      'Added tag management system with custom and system tags',
      'Improved tag organization with categories',
      'Added tag suggestions while writing',
      'Enhanced tag filtering in journal list',
      'Added tag settings to control system tag visibility'
    ]
  },
  {
    version: '1.33.0',
    date: '2025-03-26',
    changes: [
      'Added default view selection in settings',
      'Fixed anonymization persistence in AI analysis',
      'Updated AI analysis icons for better clarity',
      'Changed "AI Edit" to "Edit for Grammar"',
      'Updated support link to contact page',
      'Added contact link to landing page footer'
    ]
  },
  {
    version: '1.32.0',
    date: '2025-03-26',
    changes: [
      'Added automatic content anonymization for AI analysis',
      'Enhanced privacy protection with PII detection and removal',
      'Improved AI feature descriptions to highlight privacy focus',
      'Added privacy policy links to footer and landing page'
    ]
  },
  {
    version: '1.31.0',
    date: '2025-03-26',
    changes: [
      'Fixed duplicate entries being created during auto-save',
      'Added unique entry check to prevent duplicates',
      'Improved entry state management during creation and updates'
    ]
  },
  {
    version: '1.30.0',
    date: '2025-03-26',
    changes: [
      'Fixed mobile menu visibility in journal list view',
      'Improved mobile menu button positioning',
      'Enhanced mobile UI consistency across all views',
      'Fixed authentication state management',
      'Optimized performance and reduced memory usage'
    ]
  },
  {
    version: '1.29.0',
    date: '2025-03-26',
    changes: [
      'Added multiple AI analysis types: Summary, Polish & Enhance, and Insights & Patterns',
      'Fixed mobile menu visibility and behavior',
      'Improved mobile UI layout and accessibility',
      'Enhanced AI panel positioning and responsiveness',
      'Fixed "New Entry" button visibility in empty journal state'
    ]
  },
  {
    version: '1.28.0',
    date: '2025-03-26',
    changes: [
      'Updated AI panel label orientation',
      'Improved AI panel visual hierarchy',
      'Enhanced readability of AI controls'
    ]
  },
  {
    version: '1.27.0',
    date: '2025-03-25',
    changes: [
      'Added journal collections feature',
      'Implemented collection management UI',
      'Added cover image support for collections',
      'Enhanced navigation between collections and entries'
    ]
  },
  {
    version: '1.26.0',
    date: '2025-03-24',
    changes: [
      'Added end-to-end encryption for journal entries',
      'Implemented AES-256-GCM encryption',
      'Added encryption key management',
      'Enhanced data security and privacy'
    ]
  },
  {
    version: '1.25.0',
    date: '2025-03-23',
    changes: [
      'Added offline support',
      'Implemented local storage caching',
      'Added sync status indicators',
      'Enhanced data persistence'
    ]
  },
  {
    version: '1.24.0',
    date: '2025-03-22',
    changes: [
      'Added dark mode support',
      'Implemented theme persistence',
      'Enhanced UI contrast and readability',
      'Added automatic theme switching based on system preferences'
    ]
  },
  {
    version: '1.23.0',
    date: '2025-03-21',
    changes: [
      'Added AI-powered insights',
      'Implemented entry analysis',
      'Added sentiment tracking',
      'Enhanced writing suggestions'
    ]
  },
  {
    version: '1.22.0',
    date: '2025-03-20',
    changes: [
      'Added tag system',
      'Implemented tag filtering',
      'Added tag suggestions',
      'Enhanced entry organization'
    ]
  },
  {
    version: '1.21.0',
    date: '2025-03-19',
    changes: [
      'Added auto-save functionality',
      'Implemented draft system',
      'Added unsaved changes warning',
      'Enhanced data preservation'
    ]
  },
  {
    version: '1.20.0',
    date: '2025-03-18',
    changes: [
      'Added rich text editor',
      'Implemented formatting toolbar',
      'Added markdown support',
      'Enhanced writing experience'
    ]
  },
  {
    version: '1.19.0',
    date: '2025-03-17',
    changes: [
      'Added search functionality',
      'Implemented full-text search',
      'Added search filters',
      'Enhanced entry discovery'
    ]
  },
  {
    version: '1.18.0',
    date: '2025-03-16',
    changes: [
      'Added export functionality',
      'Implemented multiple export formats',
      'Added backup system',
      'Enhanced data portability'
    ]
  },
  {
    version: '1.17.0',
    date: '2025-03-15',
    changes: [
      'Added user settings',
      'Implemented preferences management',
      'Added customization options',
      'Enhanced user experience'
    ]
  },
  {
    version: '1.16.0',
    date: '2025-03-14',
    changes: [
      'Added mobile responsiveness',
      'Implemented touch gestures',
      'Enhanced mobile UI',
      'Added PWA support'
    ]
  },
  {
    version: '1.15.0',
    date: '2025-03-13',
    changes: [
      'Added entry statistics',
      'Implemented writing analytics',
      'Added progress tracking',
      'Enhanced insights dashboard'
    ]
  },
  {
    version: '1.14.0',
    date: '2025-03-12',
    changes: [
      'Added entry templates',
      'Implemented template management',
      'Added quick entry options',
      'Enhanced writing workflow'
    ]
  },
  {
    version: '1.13.0',
    date: '2025-03-11',
    changes: [
      'Added entry reminders',
      'Implemented notification system',
      'Added scheduling options',
      'Enhanced writing consistency'
    ]
  },
  {
    version: '1.12.0',
    date: '2025-03-10',
    changes: [
      'Added entry versioning',
      'Implemented revision history',
      'Added version comparison',
      'Enhanced entry tracking'
    ]
  },
  {
    version: '1.11.0',
    date: '2025-03-09',
    changes: [
      'Added entry sharing',
      'Implemented sharing controls',
      'Added collaboration features',
      'Enhanced social interaction'
    ]
  },
  {
    version: '1.10.0',
    date: '2025-03-08',
    changes: [
      'Added entry categories',
      'Implemented category management',
      'Added category filters',
      'Enhanced organization'
    ]
  },
  {
    version: '1.9.0',
    date: '2025-03-07',
    changes: [
      'Added entry attachments',
      'Implemented file upload',
      'Added media support',
      'Enhanced content richness'
    ]
  },
  {
    version: '1.8.0',
    date: '2025-03-06',
    changes: [
      'Added entry linking',
      'Implemented cross-references',
      'Added related entries',
      'Enhanced content connections'
    ]
  },
  {
    version: '1.7.0',
    date: '2025-03-05',
    changes: [
      'Added entry goals',
      'Implemented goal tracking',
      'Added progress indicators',
      'Enhanced motivation'
    ]
  },
  {
    version: '1.6.0',
    date: '2025-03-04',
    changes: [
      'Added entry mood tracking',
      'Implemented mood analysis',
      'Added mood trends',
      'Enhanced emotional awareness'
    ]
  },
  {
    version: '1.5.0',
    date: '2025-03-03',
    changes: [
      'Added entry prompts',
      'Implemented prompt library',
      'Added writing suggestions',
      'Enhanced creativity'
    ]
  },
  {
    version: '1.4.0',
    date: '2025-03-02',
    changes: [
      'Added entry favorites',
      'Implemented bookmarking',
      'Added quick access',
      'Enhanced navigation'
    ]
  },
  {
    version: '1.3.0',
    date: '2025-03-01',
    changes: [
      'Added entry archiving',
      'Implemented archive management',
      'Added restore options',
      'Enhanced organization'
    ]
  },
  {
    version: '1.2.0',
    date: '2025-02-28',
    changes: [
      'Added user authentication',
      'Implemented secure login',
      'Added account management',
      'Enhanced security'
    ]
  },
  {
    version: '1.1.0',
    date: '2025-02-27',
    changes: [
      'Added basic entry management',
      'Implemented CRUD operations',
      'Added entry list view',
      'Enhanced usability'
    ]
  },
  {
    version: '1.0.0',
    date: '2025-02-26',
    changes: [
      'Initial release',
      'Basic journal functionality',
      'Simple text entry',
      'Entry storage'
    ]
  }
];