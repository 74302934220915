import React, { createContext, useContext, useState, useEffect } from 'react';

type AIPerspective = 'first' | 'second' | 'third';
type DefaultView = 'original' | 'summary' | 'format' | 'analysis';

interface SettingsContextType {
  aiPerspective: AIPerspective;
  defaultView: DefaultView;
  setAiPerspective: (perspective: AIPerspective) => void;
  setDefaultView: (view: DefaultView) => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [aiPerspective, setAiPerspective] = useState<AIPerspective>(() => {
    const saved = localStorage.getItem('aiPerspective');
    return (saved as AIPerspective) || 'third';
  });
  const [defaultView, setDefaultView] = useState<DefaultView>(() => {
    const saved = localStorage.getItem('defaultView');
    return (saved as DefaultView) || 'original';
  });

  useEffect(() => {
    localStorage.setItem('aiPerspective', aiPerspective);
  }, [aiPerspective]);

  useEffect(() => {
    localStorage.setItem('defaultView', defaultView);
  }, [defaultView]);

  return (
    <SettingsContext.Provider value={{ aiPerspective, defaultView, setAiPerspective, setDefaultView }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}